import React from 'react';

import {
    Container,
    Form,
    Logo,
    Btn,
    ButtonBlock,
    TextBlock,
    ListBlock
} from './styles';
import logo from '../../assets/images/Logo_White.png';

import { useHistory, useLocation, Link } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginAccept() {
    const history = useHistory();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    const handleAccept = async e => {
        e.preventDefault();
        const code = history.location.state.token;
        const state = query.get("state");
        const redirect_uri = query.get("redirect_uri");
        
        try {
            window.location.href = `${redirect_uri}?state=${state}&code=${code}`;
        } catch (error) {
            toast.error('Erro ao linkar contas 😢');
        }
    };

    const handleReject = async e => {
        e.preventDefault();
        try {
            history.push({
                pathname: '/login',
                search: `?state=${query.get("state")}&redirect_uri=${query.get("redirect_uri")}`,
            });
        } catch (error) {
            toast.error('Erro ao redirecionar página 😢');
        }
    };

    return (<Container>
        <Form>
            <Logo src={logo} />
            <TextBlock>
                <h2>
                    Conecte-se com alexa
                </h2>
                <p>
                    Ao escolher aceitar, você concorda em dar permissão à skill Rastreou para fazer o seguinte:
                </p>
                <ListBlock>
                    <ul>
                        <li>Acesse sua conta Rastreou</li>
                    </ul>
                    <ul>
                        <li>Consultar dados de veiculos</li>
                    </ul>
                    <ul>
                        <li>Bloquear e desbloquear veiculos</li>
                    </ul>
                </ListBlock>
            </TextBlock>
            <ButtonBlock>
                <Btn onClick={handleAccept}>Aceitar</Btn>
                <Btn onClick={handleReject}>Rejeitar</Btn>
            </ButtonBlock>
        </Form>
        <ToastContainer />
    </Container>);
}

export default LoginAccept;