import axios from 'axios';
import config from '../../config';

export const getPlatforms = async email => {
  return axios.get(
    `${config.API_USERS_URL}/users?email=${email}`,
    {
      headers: {
        'x-api-key': config.API_KEY,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const login = async ({
  endpoint,
  email,
  password,
  device
}) => {
  console.log('device', device);
  return axios.post(
    `${config.API_USERS_URL}/users/login`,
    {
      endpoint,
      email,
      password,
      device
    },
    {
      headers: {
        'x-api-key': config.API_KEY,
        'Content-Type': 'application/json',
      },
    }
  );
};

