import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '../../assets/images/Logo_White.png';
import {
    Container,
    Form,
    Logo,
    Input,
    Btn,
    Select,
    SelectBlock,
    Image
} from './styles';

import { useHistory, useLocation } from "react-router-dom";

import { getPlatforms, login } from '../../services/auth';
import { getDevices } from '../../services/devices';

import gifLoading from '../../assets/loadings/logo_animation3.gif'

export default function Login() {
    const history = useHistory();
    const [userEmail, setUserEmail] = useState('');
    const [endpoint, setEndpoint] = useState({
        endpoint: null,
        image: null,
        id: 0
    });
    const [password, setPassword] = useState('');
    const [device, setDevice] = useState();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        showSearch: true,
        showDevices: false,
        showLogin: false,
    });
    const [userEndpoints, setUserEndpoints] = useState([]);
    const [userDevices, setUserDevices] = useState([]);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();

    useEffect(() => {

    }, [])

    const handleEndpoints = async e => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await getPlatforms(
                userEmail
            );
            const { data } = response;
            setUserEndpoints(data[0].endpoints);
            setEndpoint({
                endpoint: data[0].endpoints[0].endpoint,
                image: data[0].endpoints[0].image.Body
            });
            setLoading(false);
            setState({
                ...state,
                showLogin: false,
                showSearch: false,
                showDevices: true
            })
        } catch (error) {
            console.log('Erro ao tentar fazer login: ', error);
            toast.error('Erro ao buscar plataformas 😢');
            setLoading(false);
        }
    };

    const handleGetDevice = async e => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const responseDevices = await getDevices({
                token: Buffer.from(`${userEmail}:${password}`).toString('base64'),
                endpoint: endpoint.endpoint
            });

            setUserDevices(responseDevices.data);
            setDevice(responseDevices.data[0].id)
            setLoading(false);
            setState({
                ...state,
                showLogin: true,
                showSearch: false,
                showDevices: false
            })
        } catch (error) {
            console.log('Erro ao tentar fazer login: ', error);
            toast.error('Erro ao tentar fazer login 😢');
            setLoading(false);
        }
    };

    const handleLogin = async e => {
        e.preventDefault();
        setLoading(true);
        
        try {
            const response = await login({
                email: userEmail,
                password,
                endpoint: endpoint.endpoint,
                device,
            });
            history.push({
                pathname: '/token/rastreou',
                search: `?state=${query.get("state")}&redirect_uri=${query.get("redirect_uri")}`,
                state: { token: response.data.token }
            });
            setLoading(false);
        } catch (error) {
            console.log('Erro ao tentar fazer login: ', error);
            toast.error('Erro ao tentar fazer login 😢');
            setLoading(false);
        }
    };

    const handleUserEndpoint = (item) => {
        const value = userEndpoints.find((res) => res.id == item)
        setEndpoint({
            endpoint: value.endpoint,
            image: value.image.Body,
            id: value.id
        })
    }

    const handleDevice = (value) => {
        setDevice(value)
    }

    const { showLogin, showSearch, showDevices } = state;

    return (
        <Container>
            <Form>
                <Logo src={logo} />
                <Input
                    placeholder="Usuário"
                    value={userEmail}
                    onChange={e => setUserEmail(e.target.value)}
                />

                {(showDevices || showLogin) && userEndpoints.length > 0 && (
                    <>
                        <SelectBlock>
                            <Image src={`data:image/png;base64, ${endpoint.image}`} />
                            <Select
                                placeholder="Plataforma"
                                onChange={e => handleUserEndpoint(e.target.value)}
                                value={endpoint.id}
                            >
                                {userEndpoints.map((res) => {
                                    return <option value={res.id}>{res.name}</option>
                                })}
                            </Select>
                        </SelectBlock>
                        <Input
                            type="password"
                            placeholder="Senha"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </>)}

                {(showDevices || showLogin) && userDevices.length > 0 && (
                    <>
                        <SelectBlock>
                            <Select
                                placeholder="Veículos"
                                value={device}
                                onChange={e => handleDevice(e.target.value)}
                            >
                                {userDevices.map((res) => {
                                    return <option value={res.id}>{res.name}</option>
                                })}
                            </Select>
                        </SelectBlock>
                    </>)}

                {showSearch && (<Btn disabled={loading} onClick={handleEndpoints}>{loading ? <img src={gifLoading} alt="loading..." /> : "BUSCAR PLATAFORMAS"}</Btn>)}
                {showDevices && (<Btn disabled={loading} onClick={handleGetDevice}>{loading ? <img src={gifLoading} alt="loading..." /> : "BUSCAR DISPOSITIVOS"}</Btn>)}
                {showLogin && (<Btn disabled={loading} onClick={handleLogin}>{loading ? <img src={gifLoading} alt="loading..." /> : "ENTRAR"}</Btn>)}
            </Form>
            <ToastContainer />
        </Container>
    );
}
