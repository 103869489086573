import axios from 'axios';

export const getDevices = async ({
    endpoint,
    token
}) => {
  return axios.get(
    `${endpoint}/api/devices`,
    {
      headers: {
        'Authorization': `Basic ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

