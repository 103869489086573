import React from "react";
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";

import Login from '../pages/Login'
import LoginAccept from '../pages/LoginAccept'

export default function Router() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/token/rastreou">
                    <LoginAccept />
                </Route>
            </Switch>
        </BrowserRouter>
    );
}