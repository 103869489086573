import styled from 'styled-components';
import backImage from '../../assets/images/background.png';

const { innerWidth: width, innerHeight: height } = window;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${backImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: rgba(0,0,0,0.5);
  align-items: center;
  width: 300px;

  @media (min-width: 768px) {
    width: 600px;
  }
`;


export const Logo = styled.img`
  width: 20%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 70px;
`;

export const Input = styled.input`
  margin: auto;
  margin-top: 0;
  width: 80%;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 30px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: rgb(3 2 2 / 45%);
  color: #fff;
  border: 1px solid white;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #fff;
    font-weight: bold;

  }

`;

export const Btn = styled.button`
  width: 80%;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: Transparent;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  outline:none;
  margin-top: 50px;
  margin-bottom: 40px;
  padding: 10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  img {
    height: 30px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: no-drop;
  }
`;

export const Select = styled.select`
  margin: auto;
  margin-top: 0;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 25px;
  font-family: Arial, Helvetica, sans-serif;
  border: none;
  background-color: rgb(3 2 2 / 45%);
  color: white;
`

export const SelectBlock = styled.div`
  background-color: rgb(3 2 2 / 45%);
  margin-bottom: 30px;
  border-radius: 5px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  border: 1px solid white;
`

export const Image = styled.img`
  height: 30px;
  align-self: center;
`