import styled from 'styled-components';
import backImage from '../../assets/images/background.png';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-image: url(${backImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-weight: bold;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
  background-color: rgba(0,0,0,0.5);
  align-items: center;
  padding: 0 20px;

  width: 300px;

  @media (min-width: 768px) {
    width: 400px;
  }
`;


export const TextBlock = styled.div`
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    text-align: justify;
    margin-bottom: 10px;
  }

`

export const ListBlock = styled.div`
  display: flex;
  flex-direction: column;

  li {
    margin-bottom: 5px;
  }
`

export const Logo = styled.img`
  width: 20%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 20px;
`;

export const Btn = styled.button`
  width: 40%;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: rgb(3 2 2 / 45%);
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline:none;
  margin: auto;
  padding: 10px 0;
  border: 1px solid white;
  border-radius: 5px;


`;

export const ButtonBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;
  margin-bottom: 40px;
`